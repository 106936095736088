import { TextArea as FlipKitTextArea } from '@flipgrid/flipkit';
import { forwardRef, useContext } from 'react';

import GlobalContext from '~/contexts/globalContext';

import type { TextAreaProps } from '@flipgrid/flipkit/dist/src/TextArea/TextArea';
import type { ChangeEvent, KeyboardEvent } from 'react';
import type { RemoveIndex } from 'types';

type Props = Omit<RemoveIndex<TextAreaProps>, 'checked' | 'onChange'> & {
  autoFocus?: boolean;
  name: string; // Required for formData
  defaultValue?: string;
  rows?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(({ label, name, defaultValue, ...rest }, ref) => {
  const globalContext = useContext(GlobalContext);

  return (
    <FlipKitTextArea
      {...rest}
      ref={ref}
      announceLiveMessage={globalContext.announceLiveMessage}
      label={label}
      name={name}
      defaultValue={defaultValue}
    />
  );
});

export default TextArea;
